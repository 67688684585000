<template>
  <div class="container">
    <div v-if="loading">
      <Spinner />
    </div>
    <div v-else class="mb-2">
      <div class="d-flex align-items-center alert alert-info mb-3">
        <i class="fa fa-circle-info fa-xl mr-4"></i>
        <div>
          <p class="mb-1">
            <strong>Map the headings from your file with their relevant fields in Etainabl.</strong> We have attempted to guess some, but you may need
            to fill the rest.
          </p>
          <p class="mb-0">Scroll down for further details on each Etainabl field. If you need further help, please contact Etainabl Support.</p>
        </div>
      </div>
      <div v-if="requiredFields.length > 0" class="alert alert-warning mb-0">
        <strong><i class="fa fa-exclamation-triangle mr-1"></i>The following fields require a mapping:</strong>
        <span v-for="item in requiredFields" :key="item.key">
          {{ item.friendly }}{{ requiredFields.indexOf(item) >= requiredFields.length - 1 ? '' : ', ' }}
        </span>
      </div>
      <table v-if="mappingValues.length > 0" class="table table-borderless">
        <thead>
          <th>Heading</th>
          <th>Map To</th>
        </thead>
        <tbody>
          <tr v-for="(item, index) in mappingValues" :key="index">
            <td>
              <div>{{ item.heading }}</div>
            </td>
            <td>
              <select v-model="item.value" class="form-control">
                <option value=""></option>
                <option
                  v-for="value in availableFields.filter(
                    field => item.value === field.key || !mappingValues.some(h => h.value === field.key) || field.type === 'array'
                  )"
                  :key="value.key"
                  :value="value.key"
                >
                  {{ value.friendly }}
                </option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="row justify-content-between">
        <div class="col">
          <h4 class="text-success mb-2"><i class="fad fa-check mr-1"></i> All fields selected</h4>
        </div>
        <div class="col text-right">
          <button class="btn btn-success">Preview</button>
        </div>
      </div>

      <div class="d-flex justify-content-between mb-4">
        <router-link :to="{ name: 'import-upload', query: this.$route.query, params: this.$route.params }" class="btn btn-light">Back</router-link>
        <button class="btn btn-primary" :class="{ disabled: requiredFields.length > 0 }" @click="onSubmit">Submit</button>
      </div>

      <div class="info-box mb-4">
        <div class="d-flex align-items-center mb-2">
          <i class="fa fa-circle-question fa-xl mr-2"></i>
          <h3 class="mb-0">Help</h3>
        </div>
        <p class="mb-2">Below is an explanation of what each field is that you can map to, and anything else that should be considered.</p>
        <p class="mb-2">
          For fields where there is a relation (for example, Asset's have a related Entity), the relation column will show what you should enter.
        </p>
        <table v-if="Object.values(setup.info?.fields || []).length > 0" class="table">
          <thead>
            <th style="width: 66%">Field</th>
            <th>Required?</th>
            <th>Relation</th>
          </thead>
          <tbody>
            <tr v-for="(field, index) in Object.values(setup.info.fields)" :key="index">
              <td>
                <div class="font-w600">{{ field.friendly }}</div>
                <div class="pr-3">
                  <small>{{ field.description }}</small>
                </div>
              </td>
              <td>
                <span v-if="field.required" class="font-w600"><i class="fa fa-circle-check mr-1 text-success"></i> Yes</span>
              </td>
              <td>{{ field.relations ? field.relations.join(', ') : '' }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="d-flex justify-content-between mb-4">
        <router-link :to="{ name: 'import-upload', query: this.$route.query, params: this.$route.params }" class="btn btn-light">Back</router-link>
        <button class="btn btn-primary" :class="{ disabled: requiredFields.length > 0 }" @click="onSubmit">Submit</button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Spinner from '@/components/Spinner';

export default {
  name: 'ImportMapping',
  components: {
    Spinner
  },
  data() {
    return {
      loading: true,
      status: 'pending',
      mappingValues: []
    };
  },
  computed: {
    ...mapGetters({
      import: 'import/import',
      setup: 'import/setup',
      mapping: 'import/mapping',
      errorAction: 'import/errorAction',
      loadingAction: 'import/loadingAction'
    }),
    requiredFields() {
      if (!this.setup.info?.fields) return [];
      return Object.entries(this.setup.info.fields)
        .filter(([key, value]) => !this.mappingValues.some(h => h.value === key) && value.required)
        .map(([key, value]) => ({ key, ...value }));
    },
    availableFields() {
      if (!this.setup.info.fields) return [];
      return Object.entries(this.setup.info.fields).map(([key, value]) => ({ ...value, key }));
    }
  },
  async mounted() {
    if (!this.$route.params.id) {
      return this.$router.push({ name: 'import-list' });
    }

    if (this.$route.params.id && this.import._id !== this.$route.params.id) {
      await this.getImport({ id: this.$route.params.id });
    }

    if (!this.setup.info) {
      await this.getInfo({ type: this.import.type });
    }

    await this.postMapping({ id: this.import._id });

    this.mappingValues = [...(this.mapping.user.length > 0 ? this.mapping.user : this.mapping.suggested)];

    this.loading = false;
  },
  methods: {
    ...mapActions({
      getInfo: 'import/getInfo',
      getImport: 'import/get',
      postMapping: 'import/postMapping'
    }),
    ...mapMutations({
      setId: 'import/SET_ID',
      setUserMapping: 'import/SET_USER_MAPPING'
    }),
    onSubmit() {
      this.setUserMapping(this.mappingValues);

      this.$router.push({ name: 'import-preview', query: this.$route.query, params: this.$route.params });
    }
  }
};
</script>
